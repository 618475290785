import { BrandNotFound } from '@/app/components/BrandNotFound/BrandNotFound'
import SurveyObserver from '@/app/components/SurveyObserver'

export default function ThermostatNotFound() {
  return (
    <SurveyObserver observedSurveys={['MissingBrand']}>
      <BrandNotFound type="thermostat" />
    </SurveyObserver>
  )
}
